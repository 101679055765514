<template>
  <div class="tools02-report-import">
    <el-row style="margin: 0; padding: 20px 0">
      <el-col class="sub-title">
        <h3 style="margin: 0">
          Step1：ASP管理画面から成果データをダウンロード
        </h3>
        <a :href="toolData.manualData.aspCsvDownloadManualUrl" target="_blank" rel="noopener noreferrer">
          <p class="manual" style="margin: 0">【マニュアル】</p>
        </a>
      </el-col>
      <el-bol>
        <div class="red">
          ※ダウンロード時に期間や案件を絞って、成果数が少なくなるようにダウンロードするとインポート時の処理速度が速くなります。
        </div>
      </el-bol>
    </el-row>
    <el-row style="margin: 0">
      <el-col class="sub-title">
        <h3 style="margin-top: 100px">
          Step2：ASP成果データをアドタスカルへインポート
        </h3>
        <a :href="toolData.manualData.importManualUrl" target="_blank" rel="noopener noreferrer">
          <p class="manual">【マニュアル】</p>
        </a>
      </el-col>
    </el-row>
    <div class="btn-cover">
      <div class="btn-cover-left">
        <div class="button-1">
          <el-select v-model="aspId" placeholder="選択してください。" :disabled="validities[tool.codeCamel] !== 1">
            <el-option v-for="asp in supportingImportAspList" :key="asp.aspId" :label="asp.aspName"
              :value="asp.aspId"></el-option>
          </el-select>
          <ValidationErrorMessages :errorMessageList="validationErrorData.aspId" />
        </div>
        <div class="button-1" v-if="aspId === '10' || aspId === '16'">
          <el-select v-model="csvFormatId" placeholder="選択してください。" :disabled="validities[tool.codeCamel] !== 1">
            <el-option v-for="csvFormat in csvFormats[aspId]" :key="csvFormat.formatId" :label="csvFormat.name"
              :value="csvFormat.formatId"></el-option>
          </el-select>
        </div>
        <div class="button-2">
          <input v-on:change="selectedFile($event)" type="file" accept="text/csv"
            :disabled="validities[tool.codeCamel] !== 1" />
          <ValidationErrorMessages :errorMessageList="validationErrorData.uploadFile" />
        </div>
      </div>
      <div class="soushin-cover">
        <el-button v-if="loading === false" @click="importCsv" type="primary"
          :disabled="validities[tool.codeCamel] !== 1">登録</el-button>
        <div v-else-if="loading === true" v-loading="loading" style="width: 70px; height: 40px"></div>
        <span v-show="tool.codeKebab === 'facebook-cv'" class="soushin">（インポート成功と同時にPOST送信されます。）</span>
      </div>
    </div>
    <div v-if="aspId === '16'">
      <div v-if="csvFormatId === 1" class="asp-description">
        <div class="description-text">
          ※インポートする前に【注意事項】をご確認ください。
        </div>
        <div>
          <a href="https://adtasukaru.wraptas.site/744c92e09fdd4e9b99c23785aa9c9953" target="_blank"
            rel="noopener noreferrer" style="text-decoration: underline">⇒A8.netの未確定速報レポートを手動インポートする際の注意事項</a>
        </div>
      </div>
    </div>
    <div v-else-if="aspId === '98'" class="asp-description">
      <div>※成果CSVのダウンロード手順を間違えると、「該当プログラム未登録」のエラーになるので、必ずマニュアル通りの手順でダウンロードお願いします。（<a href="https://adtasukaru.wraptas.site/6ffa0de0d2734a969abb2a29d44032d4" target="_blank" rel="noopener noreferrer" style="text-decoration: underline">マニュアル</a>）</div>
    </div>
    <p v-show="aspId === '41'" class="red">プログラム登録で報酬額を追加しました。手動インポートする前に報酬額の登録をお願いします。</p>
    <el-row v-if="tool.codeKebab === 'facebook-cv'">
      <el-col>
        <EasyApi />
      </el-col>
    </el-row>
    <el-row v-else-if="
      tool.codeKebab === 'tiktok-cv' ||
      tool.codeKebab === 'line-cv' ||
      tool.codeKebab === 'yda-cv'
    ">
      <el-col>
        <ManualSendConversionEventSystem :toolId="toolId" :type="'default'" :conversionDataType="'default'" />
        <div v-if="userData.admin === true">
          <ManualSendConversionEventSystem :toolId="toolId" :type="'ctrl'" :conversionDataType="'default'" />
          <ManualSendConversionEventSystem v-if="toolId === '14'" :toolId="toolId" :type="'ctrl'"
            :conversionDataType="'handWriting'" />
        </div>
      </el-col>
    </el-row>
    <el-row class="import-result">
      <el-col>
        <el-row v-if="displayImportResultData.succeededData.totalCount > 0" class="succeeded-result">
          <el-col>
            <h4>
              <el-button type="success" icon="el-icon-check" circle class="success-icon"></el-button>正常にインポート完了:
              {{
                displayImportResultData.succeededData.totalCount | localeNum
              }}件
            </h4>
            <div v-for="(
                succeededToolData, succeededToolIndex
              ) in displayImportResultData.succeededData.toolList" :key="succeededToolIndex" class="succeeded-tools">
              <h5>
                {{ succeededToolData.name }}:
                {{ succeededToolData.resultCount }}件
              </h5>
              <div v-for="(
                  resultData, resultIndex
                ) in succeededToolData.resultList" :key="resultIndex">
                <div v-if="resultData.lineNumberList.length > 0" class="result-description">
                  {{ resultData.name }}:
                  {{ resultData.lineNumberList.length }}件（<span v-for="(
                      lineNumber, lineNumberIndex
                    ) in resultData.lineNumberList" :key="lineNumberIndex"><span
                      v-show="lineNumberIndex >= 1">,</span>{{ lineNumber }}</span>行目）<span
                    v-if="resultData.manualUrl !== null">
                    <a :href="resultData.manualUrl" target="_blank" rel="noopener noreferrer"
                      class="manual-link">→詳細はこちら</a></span>
                </div>
              </div>
              <div v-if="
                succeededToolData.totalApiResultData.succeededCount === 0 &&
                succeededToolData.totalApiResultData.failedCount === 0 &&
                succeededToolData.totalApiResultData.duplicateCount === 0
              "></div>
              <div v-else>
                <div class="result-description">
                  API送信成功:
                  {{ succeededToolData.totalApiResultData.succeededCount }}件
                </div>
                <div class="result-description">
                  API送信失敗:
                  {{ succeededToolData.totalApiResultData.failedCount }}件
                </div>
                <div class="result-description">
                  API送信済み:
                  {{ succeededToolData.totalApiResultData.duplicateCount }}件
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="displayImportResultData.errorData.totalCount > 0" class="error-result">
          <el-col>
            <h4>
              <el-button type="danger" round class="danger-icon">!</el-button>エラー: {{
                displayImportResultData.errorData.totalCount }}件
            </h4>
            <div v-for="(resultData, resultIndex) in displayImportResultData
              .errorData.resultList" :key="'r' + resultIndex">
              <h5 v-if="resultData.lineNumberList.length > 0">
                {{ resultData.name }}:
                {{ resultData.lineNumberList.length }}件（<span v-for="(
                    lineNumber, lineNumberIndex
                  ) in resultData.lineNumberList" :key="lineNumberIndex"><span v-show="lineNumberIndex >= 1">,</span>{{
                    lineNumber }}</span>行目）<span>
                  <a :href="resultData.manualUrl" target="_blank" rel="noopener noreferrer"
                    class="manual-link">→詳細はこちら</a></span>
              </h5>
            </div>
            <div v-for="(errorToolData, errorToolIndex) in displayImportResultData
              .errorData.toolList" :key="errorToolIndex" class="error-tools">
              <h5>
                {{ errorToolData.name }}: {{ errorToolData.resultCount }}件
              </h5>
              <div v-for="(
                  toolResultData, toolResultIndex
                ) in errorToolData.resultList" :key="toolResultIndex">
                <div v-if="toolResultData.lineNumberList.length > 0" class="result-description">
                  {{ toolResultData.name }}:
                  {{ toolResultData.lineNumberList.length }}件（<span v-for="(
                      toolResultLineNumber, toolResultLineNumberIndex
                    ) in toolResultData.lineNumberList" :key="toolResultLineNumberIndex"><span
                      v-show="toolResultLineNumberIndex >= 1">,</span>{{ toolResultLineNumber }}</span>行目）<span>
                    <a :href="toolResultData.manualUrl" target="_blank" rel="noopener noreferrer"
                      class="manual-link">→詳細はこちら</a></span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
import EasyApi from "@/components/Tools02/Report/Import/EasyApi.vue";
import ManualSendConversionEventSystem from "@/components/Tools02/Report/Import/ManualSendConversionEventSystem.vue";
import ValidationErrorMessages from "@/components/Ui/ValidationErrorMessages.vue";

export default {
  components: {
    EasyApi,
    ManualSendConversionEventSystem,
    ValidationErrorMessages,
  },
  props: {
    toolId: String,
  },
  data() {
    return {
      aspId: "",
      csvFormatId: 0,
      csvFormats: {
        10: [
          { formatId: 0, name: "計測パラメータ" },
          { formatId: 1, name: "成果分析" },
        ],
        16: [
          { formatId: 0, name: "新レポートβ版コンバージョンリファラレポート" },
          { formatId: 1, name: "未確定速報レポート" },
          { formatId: 2, name: "ポイントレポート" },
        ],
      },
      uploadFile: null,

      //
      importResultList: [],
      // importResultList: [
      //   {
      //     lineNumber: 1,
      //     toolId: "7",
      //     resultCode: "202",
      //     apiResultData: {
      //       succeededCount: 1,
      //       failedCount: 2,
      //       duplicateCount: 3,
      //     },
      //     squadbeyondApiResultData: {
      //       succeededCount: 1,
      //       failedCount: 0,
      //       duplicateCount: 0,
      //     },
      //   },
      //   {
      //     lineNumber: 2,
      //     toolId: "10",
      //     resultCode: "202",
      //     apiResultData: {
      //       succeededCount: 1,
      //       failedCount: 2,
      //       duplicateCount: 3,
      //     },
      //     squadbeyondApiResultData: {
      //       succeededCount: 0,
      //       failedCount: 0,
      //       duplicateCount: 1,
      //     },
      //   },
      //   {
      //     lineNumber: 3,
      //     toolId: "10",
      //     resultCode: "202",
      //     apiResultData: {
      //       succeededCount: 1,
      //       failedCount: 2,
      //       duplicateCount: 3,
      //     },
      //     squadbeyondApiResultData: {
      //       succeededCount: 0,
      //       failedCount: 0,
      //       duplicateCount: 1,
      //     },
      //   },
      //   {
      //     lineNumber: 4,
      //     toolId: "10",
      //     resultCode: "202",
      //     apiResultData: {
      //       succeededCount: 1,
      //       failedCount: 2,
      //       duplicateCount: 3,
      //     },
      //     squadbeyondApiResultData: {
      //       succeededCount: 1,
      //       failedCount: 0,
      //       duplicateCount: 0,
      //     },
      //   },
      //   {
      //     lineNumber: 5,
      //     toolId: "10",
      //     resultCode: "202",
      //     apiResultData: {
      //       succeededCount: 1,
      //       failedCount: 2,
      //       duplicateCount: 3,
      //     },
      //     squadbeyondApiResultData: {
      //       succeededCount: 0,
      //       failedCount: 1,
      //       duplicateCount: 0,
      //     },
      //   },
      //   {
      //     lineNumber: 6,
      //     toolId: "0",
      //     resultCode: "441",
      //     apiResultData: {
      //       succeededCount: 0,
      //       failedCount: 0,
      //       duplicateCount: 0,
      //     },
      //     squadbeyondApiResultData: {
      //       succeededCount: 0,
      //       failedCount: 0,
      //       duplicateCount: 0,
      //     },
      //   },
      // ],
      validationErrorData: {
        aspId: [],
        uploadFile: [],
      },

      //
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "tool",
      "userData",
      "apiErrorMessage",
      "tools",
      "supportingImportAspList",
    ]),
    ...mapGetters("tools02", ["toolListInTopPageOrder"]),
    ...mapGetters("subscription", ["validities"]),
    toolData() {
      return this.$store.getters["toolData"](this.tool.id);
    },
    displayImportResultData() {
      // define
      const displayImportResultData = {
        succeededData: {
          totalCount: 0,
          toolList: [],
        },
        errorData: {
          totalCount: 0,
          resultList: [],
          toolList: [],
        },
      };

      //
      for (const importResultData of this.importResultList) {
        if (importResultData.resultCode.substring(0, 1) === "2") {
          //
          this.addImportResultDataToDisplayImportResultData(
            importResultData,
            displayImportResultData.succeededData
          );

          //
          displayImportResultData.succeededData.totalCount++;
        } else if (importResultData.resultCode.substring(0, 1) === "4") {
          this.addImportResultDataToDisplayImportResultData(
            importResultData,
            displayImportResultData.errorData
          );
          displayImportResultData.errorData.totalCount++;
        } else {
          //
        }
      }

      // toolListの並べ替え
      displayImportResultData["succeededData"].toolList =
        this.getTopPageOrderedToolList(
          displayImportResultData["succeededData"].toolList
        );
      displayImportResultData["errorData"].toolList =
        this.getTopPageOrderedToolList(
          displayImportResultData["errorData"].toolList
        );

      //
      return displayImportResultData;
    },
  },
  watch: {},
  created() {
    setTimeout(function () {
      $(".el-select-dropdown__wrap").css("max-height", "500px");
    }, 500);
  },
  methods: {
    importCsv() {
      this.loading = true;
      let formData = new FormData();
      formData.append("tool_id", this.tool.id);
      formData.append("asp_id", this.aspId);
      formData.append("csv_format_id", this.csvFormatId);
      formData.append("upload_file", this.uploadFile);
      let config = { headers: { "content-type": "multipart/form-data" } };
      this.$axios
        .post(`${this.rt}/l/api/tools02/import/import-csv`, formData, config)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.data.status === 200) {
            if (response.data.importResultList.length > 0) {
              alert("インポート完了しました。");
              this.importResultList = response.data.importResultList;
              this.$store.dispatch("setUserData");
            } else {
              alert(
                "他のASPの成果CSVをアップロードしている可能性があります。確認してください。"
              );
            }
            this.validationErrorData.aspId = [];
            this.validationErrorData.uploadFile = [];
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else if (response.data.status === 432) {
            alert(response.data.message);
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    selectedFile(e) {
      e.preventDefault();
      let files = e.target.files;
      this.uploadFile = files[0];
      this.$forceUpdate();
    },
    searchAndAddResultData(
      resultCode,
      objectList,
      insertResultData,
      lineNumber
    ) {
      const foundResultData = objectList.find(
        (resultData) => resultData.code === resultCode
      );
      if (foundResultData === undefined) {
        objectList.push(insertResultData);
      } else {
        foundResultData.lineNumberList.push(lineNumber);
      }
    },
    searchAndAddToolData(
      lineNumber,
      toolId,
      resultCode,
      apiResultData,
      objectData
    ) {
      // storeToolData
      const storeToolData = this.$store.getters["toolData"](toolId);

      //
      const importResultDefinitionData =
        this.$store.getters["tools02/importResultDefinitionData"](resultCode);
      let resultName = importResultDefinitionData.name;
      let resultManualUrl = importResultDefinitionData.manualUrl;
      if (toolId === "16") {
        if (resultCode === "201") {
          resultName = "API送信成功";
        } else if (resultCode === "202") {
          resultName = "API送信済み";
          resultManualUrl = null;
        } else {
          //
        }
      } else {
        //
      }

      //
      const insertResultData = {
        code: resultCode,
        name: resultName,
        manualUrl: resultManualUrl,
        lineNumberList: [lineNumber],
      };

      //
      const insertToolData = {
        toolId: toolId,
        name: storeToolData !== undefined ? storeToolData.mediaName : "aiueo",
        resultList: [insertResultData],
        resultCount: 1,
        totalApiResultData: {
          succeededCount: apiResultData.succeededCount,
          failedCount: apiResultData.failedCount,
          duplicateCount: apiResultData.duplicateCount,
        },
      };

      //
      if (toolId === "0") {
        this.searchAndAddResultData(
          resultCode,
          objectData.resultList,
          insertResultData,
          lineNumber
        );
      } else {
        const foundToolData = objectData.toolList.find(
          (toolData) => toolData.toolId === toolId
        );
        if (foundToolData === undefined) {
          objectData.toolList.push(insertToolData);
        } else {
          this.searchAndAddResultData(
            resultCode,
            foundToolData.resultList,
            insertResultData,
            lineNumber
          );

          //
          foundToolData.resultCount++;
          foundToolData.totalApiResultData.succeededCount +=
            apiResultData.succeededCount;
          foundToolData.totalApiResultData.failedCount +=
            apiResultData.failedCount;
          foundToolData.totalApiResultData.duplicateCount +=
            apiResultData.duplicateCount;
        }
      }
    },
    addImportResultDataToDisplayImportResultData(importResultData, objectData) {
      //
      const lineNumber = importResultData.lineNumber;
      const toolId = importResultData.toolId;
      const resultCode = importResultData.resultCode;
      const apiResultData = importResultData.apiResultData;

      //
      this.searchAndAddToolData(
        lineNumber,
        toolId,
        resultCode,
        apiResultData,
        objectData
      );

      // Squadbeyond
      const squadbeyondApiResultData =
        importResultData.squadbeyondApiResultData;
      if (
        squadbeyondApiResultData.succeededCount === 1 ||
        squadbeyondApiResultData.failedCount === 1 ||
        squadbeyondApiResultData.duplicateCount === 1
      ) {
        //
        const squadbeyondToolId = "16";
        let squadbeyondResultCode = undefined;
        if (squadbeyondApiResultData.succeededCount === 1) {
          squadbeyondResultCode = "201";
        } else if (squadbeyondApiResultData.failedCount === 1) {
          squadbeyondResultCode = "401";
        } else if (squadbeyondApiResultData.duplicateCount === 1) {
          squadbeyondResultCode = "202";
        } else {
          // no action.
        }

        //
        this.searchAndAddToolData(
          lineNumber,
          squadbeyondToolId,
          squadbeyondResultCode,
          {
            succeededCount: 0,
            failedCount: 0,
            duplicateCount: 0,
          },
          objectData
        );
      } else {
        // no action.
      }
    },
    getTopPageOrderedToolList(toolList) {
      let toolIndex = 0;
      const toolIdOrder = {};
      for (const toolDataInTopPageOrder of this.toolListInTopPageOrder) {
        toolIdOrder[toolDataInTopPageOrder.toolId] = toolIndex;
        toolIndex++;
      }
      const topPageOrderedToolList = toolList.sort((a, b) => {
        return toolIdOrder[a.toolId] - toolIdOrder[b.toolId];
      });

      //
      return topPageOrderedToolList;
    },

    // old
    // importCsv() {
    //   this.loading = true;
    //   let formData = new FormData();
    //   formData.append("tool_id", this.tool.id);
    //   formData.append("asp_id", this.aspId);
    //   formData.append("csv_format_id", this.csvFormatId);
    //   formData.append("upload_file", this.uploadFile);
    //   let config = { headers: { "content-type": "multipart/form-data" } };
    //   this.$axios
    //     .post(`${this.rt}/api/tools02/importAspCsv.php`, formData, config)
    //     .then((response) => {
    //       console.log(response);
    //       this.loading = false;
    //       if (response.data.status === 200) {
    //         if (response.data.importResultList.length > 0) {
    //           alert("インポート完了しました。");
    //           this.importResultList = response.data.importResultList;
    //           this.$store.dispatch("setUserData");
    //         } else {
    //           alert(
    //             "他のASPの成果CSVをアップロードしている可能性があります。確認してください。"
    //           );
    //         }
    //         return;
    //       } else if (
    //         response.data.status === 400 ||
    //         response.data.status === 500
    //       ) {
    //         alert(response.data.message);
    //         return;
    //       } else if (response.data.status === 401) {
    //         alert(response.data.message);
    //         this.$router.push("/login");
    //       } else {
    //         alert(this.apiErrorMessage);
    //         return;
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #409eff;
  text-decoration: none;
}

a.error {
  color: red;
  text-decoration: underline;
}

a.manual-link {
  font-weight: bold;
}

.manual {
  padding-left: 20px;
  font-size: 20px;
}

.sub-title {
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;

    .manual {
      margin-top: 0;
      padding-left: 0;
    }
  }
}

.btn-cover {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 1286px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-cover-left {
    // width: 500px; // 変更しました（2023/03/29）
    width: auto; // 変更しました（2023/03/29）
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      width: 450px;
      flex-direction: column;
      align-items: flex-start;
      //  padding: 10px 0;
    }
  }

  .button-1 {
    width: 30%;
    min-width: 217px;
    margin-right: 10px; // 変更しました（2023/03/29）
  }

  .button-2 {
    width: 30%;
    min-width: 253px;

    @media screen and (max-width: 767px) {
      padding-left: 5px;
    }
  }
}

.asp-description {
  margin-top: 10px;

  .description-text {
    color: red;
    font-weight: bold;
  }
}

.soushin-cover {
  @media screen and (max-width: 1286px) {
    margin-top: 10px;
  }

  .soushin {
    @media screen and (max-width: 767px) {
      margin-left: 0px;
      font-size: 11px;
    }
  }
}

.import-result {

  .succeeded-result,
  .error-result {
    h4 {
      margin-bottom: 12px;
    }

    h5 {
      font-size: 14px;
      padding-left: 15px;
      margin-top: 10px;
      margin-bottom: 3px;

      &::before {
        content: "・";
      }
    }

    .succeeded-tools,
    .error-tools {
      .result-description {
        padding-left: 30px;
        font-size: 14px;

        &::before {
          content: "-";
        }
      }
    }
  }
}
</style>
