<template>
  <div class="payment-active-plan-data">
    <h3 class="back-gray">現在決済されるクレジットカード</h3>
    <div class="card-area">
      <div class="card-text-cover">
        <div class="card-status-text">
          <p v-if="registeredCardData !== null">
            AMEX：*****0001<br>
            有効期限：03/28
          </p>
          <p v-else>カード情報の登録がありません</p>
          <!-- <span>		{{ registeredCardData.expire }}{{ registeredCardData.brand }} {{ registeredCardData.cardNo }}</span> -->
        </div>
        <el-button @click="editCard()" type="success" size="mini">カード変更</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Question from "@/components/Ui/Question.vue";

export default {
  name: "PaymentActivePlanData",
  components: {
    // Question,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "rt",
      "stockPoint",
      "userData",
      "activePlanData",
      "apiErrorMessage",
      "registeredCardList",
    ]),
    registeredCardData() {
      // 
      let registeredCardData = null;
      if (this.registeredCardList.length > 0) {
        registeredCardData = this.registeredCardList[0];
      } else {
        // no action.
      }

      return registeredCardData;
    }
  },
  created() {
    this.$store.dispatch('setRegisteredCardList');
  },
  methods: {
    editCard() {
      this.$axios
        .get(`${this.rt}/l/api/payment/get-card-editor-url`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            // location.href = response.data.url;
            window.open(response.data.url, "_blank");
          } else {
            alert(this.apiErrorMessage);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.back-gray {
  margin-bottom: 1em!important;
}
.card-area {
  display: flex;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }

  .card-text-cover {
    display: flex;
    align-items: flex-start;
    padding: 1em;

    .card-status-text {
      margin-right: 2em;
      p {
        margin: 0;
      }
    }
  }
}
</style>
